<template>
  <b-card
    no-body
  >
    <b-card-body
      :title="title"
    >
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >

          <!-- basic -->
          <b-form-group
            :label="$t('Marka')"
            label-for="marka"
          >
            <b-form-input
              id="marka"
              v-model="data.marka"
              :placeholder="$t('Marka')"
            />
            <small
              v-if="formErrors['marka']"
              class="text-danger"
            >
              {{ $t(formErrors['marka'][0]) }}
            </small>
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('Tip/Model')"
            label-for="tip"
          >
            <b-form-input
              id="tip"
              v-model="data.tip"
              :placeholder="$t('Tip/Model vozila')"
            />
            <small
              v-if="formErrors['tip']"
              class="text-danger"
            >
              {{ $t(formErrors['tip'][0]) }}
            </small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >

          <!-- basic -->
          <b-form-group
            :label="$t('RegistarskiBroj')"
            label-for="regbroj"
          >
            <b-form-input
              id="regbroj"
              v-model="data.regbroj"
              :placeholder="$t('RegistarskiBroj')"
            />
            <small
              v-if="formErrors['regbroj']"
              class="text-danger"
            >
              {{ $t(formErrors['regbroj'][0]) }}
            </small>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('KMRedovanServis')"
            label-for="km_redovan_servis"
          >
            <b-form-input
              id="km_redovan_servis"
              v-model="data.km_redovan_servis"
              :placeholder="$t('KMRedovanServis')"
              type="number"
            />
            <small
              v-if="formErrors['km_redovan_servis']"
              class="text-danger"
            >
              {{ $t(formErrors['km_redovan_servis'][0]) }}
            </small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >

          <!-- basic -->
          <b-form-group
            :label="$t('DatumTehničkog')"
            label-for="datum_tehnickog"
          >
            <b-form-datepicker
              id="datum_tehnickog"
              v-model="data.datum_tehnickog"
              :placeholder="$t('DatumTehnickog')"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :locale="this.$i18n.locale"
              :hide-header="hideHeader"
              today-button
              reset-button
              close-button
              show-decade-nav
              :label-today-button="$t('Danas')"
              :label-reset-button="$t('Ponisti')"
              :label-close-button="$t('Zatvori')"
              :label-prev-year="$t('PrethodnaGodina')"
              :label-prev-month="$t('PrethodniMesec')"
              :label-next-year="$t('SledecaGodina')"
              :label-next-month="$t('SledeciMesec')"
              :label-current-month="$t('TekuciMesec')"
              :label-prev-decade="$t('MinusDesetGodina')"
              :label-next-decade="$t('PlusDesetGodina')"
              label-help=""
              no-flip
            />
            <small
              v-if="formErrors['datum_tehnickog']"
              class="text-danger"
            >
              {{ $t(formErrors['datum_tehnickog'][0]) }}
            </small>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >

          <!-- basic -->
          <b-form-group
            :label="$t('PočetakRegistracije')"
            label-for="pocregistracije"
          >
            <b-form-datepicker
              id="pocregistracije"
              v-model="data.pocregistracije"
              :placeholder="$t('PocetakRegistracije')"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :locale="this.$i18n.locale"
              :hide-header="hideHeader"
              today-button
              reset-button
              close-button
              show-decade-nav
              :label-today-button="$t('Danas')"
              :label-reset-button="$t('Ponisti')"
              :label-close-button="$t('Zatvori')"
              :label-prev-year="$t('PrethodnaGodina')"
              :label-prev-month="$t('PrethodniMesec')"
              :label-next-year="$t('SledecaGodina')"
              :label-next-month="$t('SledeciMesec')"
              :label-current-month="$t('TekuciMesec')"
              :label-prev-decade="$t('MinusDesetGodina')"
              :label-next-decade="$t('PlusDesetGodina')"
              label-help=""
              no-flip
            />
            <small
              v-if="formErrors['pocregistracije']"
              class="text-danger"
            >
              {{ $t(formErrors['pocregistracije'][0]) }}
            </small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('Kategorija')"
            label-for="kategorija"
          >
            <v-select
              v-model="kategorija"
              label="text"
              :options="kategorije"
              :placeholder="$t('IzaberiteKategoriju')"
            />
            <small
              v-if="formErrors['kategorija_id']"
              class="text-danger"
            >
              {{ $t(formErrors['kategorija_id'][0]) }}
            </small>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('OdgovornoLice')"
            label-for="odglice"
          >
            <v-select
              v-model="odgovorno_lice"
              label="text"
              :options="zaposleni"
              :placeholder="$t('IzaberiteOdgovornoLice')"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-body
      :title="$t('Oprema')"
    >
      <b-row>
        <b-col
          md="2"
          xl="2"
          class="mb-1"
        >
          <b-form-checkbox
            id="prva_pomoc"
            v-model="data.prva_pomoc"
            checked="false"
            name="check-button"
            class="custom-control-success"
            inline
          >
            {{ $t('PrvaPomoc') }}
          </b-form-checkbox>
        </b-col>
        <b-col
          md="2"
          xl="2"
          class="mb-1"
        >
          <b-form-checkbox
            id="pp_aparat"
            v-model="data.pp_aparat"
            checked="false"
            name="check-button"
            class="custom-control-success"
            inline
          >
            {{ $t('PPAparat') }}
          </b-form-checkbox>
        </b-col>
        <b-col
          md="2"
          xl="2"
          class="mb-1"
        >
          <b-form-checkbox
            id="tahograf"
            v-model="data.tahograf"
            checked="false"
            name="check-button"
            class="custom-control-success"
            inline
          >
            {{ $t('Tahograf') }}
          </b-form-checkbox>
        </b-col>
        <b-col
          md="2"
          xl="2"
          class="mb-1"
        >
          <b-form-checkbox
            id="tng"
            v-model="data.tng"
            checked="false"
            name="check-button"
            class="custom-control-success"
            inline
          >
            {{ $t('TNG') }}
          </b-form-checkbox>
        </b-col>
        <b-col
          md="2"
          xl="2"
          class="mb-1"
        >
          <b-form-checkbox
            id="sajla_za_vucu"
            v-model="data.sajla_za_vucu"
            checked="false"
            name="check-button"
            class="custom-control-success"
            inline
          >
            {{ $t('SajlaZaVucu') }}
          </b-form-checkbox>
        </b-col>
        <b-col
          md="2"
          xl="2"
          class="mb-1"
        >
          <b-form-checkbox
            id="trougao"
            v-model="data.trougao"
            checked="false"
            name="check-button"
            class="custom-control-success"
            inline
          >
            {{ $t('Trougao') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('DatumVazenjaPrvePomoci')"
            label-for="datum_isteka_prve_pomoci"
          >
            <b-form-datepicker
              id="datum_isteka_prve_pomoci"
              v-model="data.datum_isteka_prve_pomoci"
              :disabled="!data.prva_pomoc || data.prva_pomoc == false"
              :placeholder="$t('IzaberiDatum')"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :locale="this.$i18n.locale"
              :hide-header="hideHeader"
              today-button
              reset-button
              close-button
              show-decade-nav
              :label-today-button="$t('Danas')"
              :label-reset-button="$t('Ponisti')"
              :label-close-button="$t('Zatvori')"
              :label-prev-year="$t('PrethodnaGodina')"
              :label-prev-month="$t('PrethodniMesec')"
              :label-next-year="$t('SledecaGodina')"
              :label-next-month="$t('SledeciMesec')"
              :label-current-month="$t('TekuciMesec')"
              :label-prev-decade="$t('MinusDesetGodina')"
              :label-next-decade="$t('PlusDesetGodina')"
              label-help=""
              no-flip
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('DatumVazenjaPPAparat')"
            label-for="datum_isteka_pp_aparata"
          >
            <b-form-datepicker
              id="datum_isteka_pp_aparata"
              v-model="data.datum_isteka_pp_aparata"
              :disabled="!data.pp_aparat || data.pp_aparat == false"
              :placeholder="$t('IzaberiDatum')"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :locale="this.$i18n.locale"
              :hide-header="hideHeader"
              today-button
              reset-button
              close-button
              show-decade-nav
              :label-today-button="$t('Danas')"
              :label-reset-button="$t('Ponisti')"
              :label-close-button="$t('Zatvori')"
              :label-prev-year="$t('PrethodnaGodina')"
              :label-prev-month="$t('PrethodniMesec')"
              :label-next-year="$t('SledecaGodina')"
              :label-next-month="$t('SledeciMesec')"
              :label-current-month="$t('TekuciMesec')"
              :label-prev-decade="$t('MinusDesetGodina')"
              :label-next-decade="$t('PlusDesetGodina')"
              label-help=""
              no-flip
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('DatumVazenjaTahograf')"
            label-for="datum_isteka_tahografa"
          >
            <b-form-datepicker
              id="datum_isteka_tahografa"
              v-model="data.datum_isteka_tahografa"
              :disabled="!data.tahograf || data.tahograf == false"
              :placeholder="$t('IzaberiDatum')"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :locale="this.$i18n.locale"
              :hide-header="hideHeader"
              today-button
              reset-button
              close-button
              show-decade-nav
              :label-today-button="$t('Danas')"
              :label-reset-button="$t('Ponisti')"
              :label-close-button="$t('Zatvori')"
              :label-prev-year="$t('PrethodnaGodina')"
              :label-prev-month="$t('PrethodniMesec')"
              :label-next-year="$t('SledecaGodina')"
              :label-next-month="$t('SledeciMesec')"
              :label-current-month="$t('TekuciMesec')"
              :label-prev-decade="$t('MinusDesetGodina')"
              :label-next-decade="$t('PlusDesetGodina')"
              label-help=""
              no-flip
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('DatumAtestaTng')"
            label-for="datum_atesta_tng"
          >
            <b-form-datepicker
              id="datum_atesta_tng"
              v-model="data.datum_atesta_tng"
              :disabled="!data.tng || data.tng == false"
              :placeholder="$t('IzaberiDatum')"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :locale="this.$i18n.locale"
              :hide-header="hideHeader"
              today-button
              reset-button
              close-button
              show-decade-nav
              :label-today-button="$t('Danas')"
              :label-reset-button="$t('Ponisti')"
              :label-close-button="$t('Zatvori')"
              :label-prev-year="$t('PrethodnaGodina')"
              :label-prev-month="$t('PrethodniMesec')"
              :label-next-year="$t('SledecaGodina')"
              :label-next-month="$t('SledeciMesec')"
              :label-current-month="$t('TekuciMesec')"
              :label-prev-decade="$t('MinusDesetGodina')"
              :label-next-decade="$t('PlusDesetGodina')"
              label-help=""
              no-flip
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-body
      v-if="kategorija.value >= 8 && kategorija.value <= 14"
      :title="$t('Tahograf')"
    >
      <b-row>
        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('VrstaTahografa')"
            label-for="vrsta_tahografa"
          >
            <v-select
              v-model="vrsta_tahografa"
              label="text"
              :options="vrste_tahografa"
              :placeholder="$t('IzaberiteVrstu')"
            />
            <small
              v-if="formErrors['vrsta_tahografa']"
              class="text-danger"
            >
              {{ $t(formErrors['vrsta_tahografa'][0]) }}
            </small>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('DatumServisaTahograf')"
            label-for="datum_servisa_tahografa"
          >
            <b-form-datepicker
              id="datum_servisa_tahografa"
              v-model="data.datum_servisa_tahografa"
              :placeholder="$t('IzaberiDatum')"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :locale="this.$i18n.locale"
              :hide-header="hideHeader"
              today-button
              reset-button
              close-button
              show-decade-nav
              :label-today-button="$t('Danas')"
              :label-reset-button="$t('Ponisti')"
              :label-close-button="$t('Zatvori')"
              :label-prev-year="$t('PrethodnaGodina')"
              :label-prev-month="$t('PrethodniMesec')"
              :label-next-year="$t('SledecaGodina')"
              :label-next-month="$t('SledeciMesec')"
              :label-current-month="$t('TekuciMesec')"
              :label-prev-decade="$t('MinusDesetGodina')"
              :label-next-decade="$t('PlusDesetGodina')"
              label-help=""
              no-flip
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="vrsta_tahografa.value === 'digitalni'"
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('DatumPreuzimanjaPodatakaTahograf')"
            label-for="datum_preuzimanja_podataka_tahograf"
          >
            <b-form-datepicker
              id="datum_preuzimanja_podataka_tahograf"
              v-model="data.datum_preuzimanja_podataka_tahograf"
              :placeholder="$t('IzaberiDatum')"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :locale="this.$i18n.locale"
              :hide-header="hideHeader"
              today-button
              reset-button
              close-button
              show-decade-nav
              :label-today-button="$t('Danas')"
              :label-reset-button="$t('Ponisti')"
              :label-close-button="$t('Zatvori')"
              :label-prev-year="$t('PrethodnaGodina')"
              :label-prev-month="$t('PrethodniMesec')"
              :label-next-year="$t('SledecaGodina')"
              :label-next-month="$t('SledeciMesec')"
              :label-current-month="$t('TekuciMesec')"
              :label-prev-decade="$t('MinusDesetGodina')"
              :label-next-decade="$t('PlusDesetGodina')"
              label-help=""
              no-flip
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-body>
      <b-row>
        <b-col
          md="4"
          xl="4"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="DodajVozilo"
          >
            {{ $t('Snimi') }}
          </b-button>
          <router-link :to="{name: 'autoskola-vozila'}">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
            >
              {{ $t('Odustani') }}
            </b-button>
          </router-link>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BFormInput, BRow, BCol, BFormGroup, BCard, BCardBody, BFormDatepicker, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormDatepicker,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      title: '',
      zaposleni: [],
      data: {},
      hideHeader: true,
      kategorije: [],
      kategorija: [],
      odgovorno_lice: [],
      formErrors: [],
      vrste_tahografa: [
        { value: '', text: this.$i18n.t('Izaberi') },
        { value: 'analogni', text: this.$i18n.t('Analogni') },
        { value: 'digitalni', text: this.$i18n.t('Digitalni') },
      ],
      vrsta_tahografa: [],
    }
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    pokupiListu() {
      this.$http.get('/autoskole-administratori/zaposleni').then(r => {
        Object.entries(r.data).forEach(value => {
          this.zaposleni.push({ value: value[1].id, text: `${value[1].ime} ${value[1].prezime}` })
        })
        this.$http.get('/autoskole-administratori/kategorije').then(re => {
          Object.entries(re.data).forEach(value => {
            if (value[1].tip === 'osnovna') {
              this.kategorije.push({ value: value[1].id, text: `${value[1].naziv}` })
            }
          })
          if (typeof this.$route.params.id !== 'undefined') {
            this.title = this.$i18n.t('IzmeniVozilo')
            this.$http.get(`/autoskole-administratori/vozila/${this.$route.params.id}/edit`).then(response => {
              this.data = response.data
              const [selektovanaKategorija] = this.kategorije.filter(e => e.value === response.data.kategorija_id)
              this.kategorija = selektovanaKategorija
              const [selektovanoLice] = this.zaposleni.filter(e => e.value === response.data.odglice)
              this.odgovorno_lice = selektovanoLice
              const [selektovanaVrstaTahografa] = typeof response.data.vrsta_tahografa === 'undefined' ? [] : (this.vrste_tahografa.filter(e => e.value === response.data.vrsta_tahografa))
              this.vrsta_tahografa = selektovanaVrstaTahografa
            })
          } else {
            this.title = this.$i18n.t('DodajVozilo')
          }
        })
      })
    },
    DodajVozilo() {
      this.formErrors = []
      const voziloData = this.data
      voziloData.odglice = typeof this.odgovorno_lice !== 'undefined' ? this.odgovorno_lice.value : ''
      voziloData.kategorija_id = this.kategorija.value
      voziloData.vrsta_tahografa = typeof this.vrsta_tahografa !== 'undefined' ? this.vrsta_tahografa.value : ''
      if (typeof this.$route.params.id !== 'undefined' && this.$route.params.id !== 'kreiraj') {
        this.$http.put(`/autoskole-administratori/vozila/${this.$route.params.id}`, voziloData)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('PodaciSuSnimljeni'),
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.formErrors = error.response.data.errors
            }
          })
      } else {
        this.$http.post('/autoskole-administratori/vozila', voziloData)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('PodaciSuSnimljeni'),
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'autoskola-vozila' })
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.formErrors = error.response.data.errors
            }
          })
      }
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
